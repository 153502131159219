import '../App.css'
import Navigation from '../componets/Navigation'
import Footer from '../componets/Footer'
import './repeat.css'

function Ofiarodawcy(){
    return (
        <>
            <Navigation />
            <div className='article'>
            <div className='title'>OFIARA NA POTRZEBY KOŚCIOŁA SIERPIEŃ 2024</div>
                <table>
                <tr><td>1.</td><td>Brzeźniak – Łowiczki Graniczna 38</td><td>50</td><td>zł</td></tr>
                <tr><td>2.</td><td>Czopek – Łowiczki Centralna 216</td><td>100</td><td>zł</td></tr>
                <tr><td>3.</td><td>T.Z.Dyrda – Łowiczki</td><td>100</td><td>zł</td></tr>
                <tr><td>4.</td><td>Jan Gliwa – Łowiczki</td><td>200</td><td>zł</td></tr>
                <tr><td>5.</td><td>A.S.Głąb – Łowiczki</td><td>100</td><td>zł</td></tr>
                <tr><td>6.</td><td>J.J.Głąb – Łowiczki</td><td>100</td><td>zł</td></tr>
                <tr><td>7.</td><td>Maria Głąb – Łowiczki Centralna 80</td><td>100</td><td>zł</td></tr>
                <tr><td>8.</td><td>L.P.Gurdek – Łowiczki</td><td>100</td><td>zł</td></tr>
                <tr><td>9.</td><td>S.I – Łowiczki</td><td>200</td><td>zł</td></tr>
                <tr><td>10.</td><td>Helena Jurkowska – Łowiczki</td><td>100</td><td>zł</td></tr>
                <tr><td>11.</td><td>Anna Kciuk – Łowiczki Centralna 6</td><td>50</td><td>zł</td></tr>
                <tr><td>12.</td><td>I.Miętka – Łowiczki</td><td>50</td><td>zł</td></tr>
                <tr><td>13.</td><td>P.A.Oleś – Łowiczki Centralna</td><td>100</td><td>zł</td></tr>
                <tr><td>14.</td><td>Pardygał – Łowiczki</td><td>100</td><td>zł</td></tr>
                <tr><td>15.</td><td>M.J.Seremet – Łowiczki</td><td>200</td><td>zł</td></tr>
                <tr><td>16.</td><td>B.A.Wojtaszek – Łowiczki</td><td>100</td><td>zł</td></tr>
                <tr><td>17.</td><td>Jerzy Wójcik – Łowiczki Cyprysowa 15</td><td>100</td><td>zł</td></tr>
                <tr><td>18.</td><td>Bronisława Antecka</td><td>100</td><td>zł</td></tr>
                <tr><td>19.</td><td>Kazimiera Augustyn – Modrzewiowa 42</td><td>50</td><td>zł</td></tr>
                <tr><td>20.</td><td>Bernaś, Momot – Akacjowa 31</td><td>100</td><td>zł</td></tr>
                <tr><td>21.</td><td>Mieczysław Bernaś – Modrzewiowa 47</td><td>100</td><td>zł</td></tr>
                <tr><td>22.</td><td>M.S.Boba – Andrychowska 352</td><td>100</td><td>zł</td></tr>
                <tr><td>23.</td><td>L.W.Borowscy – Andrychowska 307</td><td>100</td><td>zł</td></tr>
                <tr><td>24.</td><td>Brusik – Andrychowska 280</td><td>100</td><td>zł</td></tr>
                <tr><td>25.</td><td>Barbara Mirosław Bulek – Andrychowska 118</td><td>100</td><td>zł</td></tr>
                <tr><td>26.</td><td>M.G.Całus</td><td>100</td><td>zł</td></tr>
                <tr><td>27.</td><td>M.J.Dedko – Andrychowska 3</td><td>50</td><td>zł</td></tr>
                <tr><td>28.</td><td>K.J.Domider – Andrychowska 216</td><td>100</td><td>zł</td></tr>
                <tr><td>29.</td><td>Dziedzic – Lipowa 25A</td><td>100</td><td>zł</td></tr>
                <tr><td>30.</td><td>B.Frączek – Modrzewiowa 2</td><td>100</td><td>zł</td></tr>
                <tr><td>31.</td><td>D.A.Frączek – Lipowa</td><td>100</td><td>zł</td></tr>
                <tr><td>32.</td><td>I.S.Frączek – Andrychowska 163</td><td>100</td><td>zł</td></tr>
                <tr><td>33.</td><td>Mirosław Frączek – Lipowa 68</td><td>100</td><td>zł</td></tr>
                <tr><td>34.</td><td>M.R.Frączek – Andrychowska 227</td><td>100</td><td>zł</td></tr>
                <tr><td>35.</td><td>Stanisław Czesława Frączek – Kasztanowa 134</td><td>100</td><td>zł</td></tr>
                <tr><td>36.</td><td>T.J.Frączek – Andrychowska </td><td>100</td><td>zł</td></tr>
                <tr><td>37.</td><td>T.K.Frączek – Andrychowska 297</td><td>100</td><td>zł</td></tr>
                <tr><td>38.</td><td>Marcin Gagracz – Akacjowa</td><td>100</td><td>zł</td></tr>
                <tr><td>39.</td><td>A.G.Gancarczyk – Kasztanowa 131</td><td>200</td><td>zł</td></tr>
                <tr><td>40.</td><td>Józef Głąb – Modrzewiowa 43</td><td>200</td><td>zł</td></tr>
                <tr><td>41.</td><td>W.M.Gorzkowscy</td><td>100</td><td>zł</td></tr>
                <tr><td>42.</td><td>Górecki, Kobiałka</td><td>150</td><td>zł</td></tr>
                <tr><td>43.</td><td>Gryska, Hałat – Andrychowska 305</td><td>100</td><td>zł</td></tr>
                <tr><td>44.</td><td>Hałatek – Lipowa</td><td>100</td><td>zł</td></tr>
                <tr><td>45.</td><td>Jarnot – Kasztanowa 118</td><td>100</td><td>zł</td></tr>
                <tr><td>46.</td><td>Jarosz – Andrychowska 32</td><td>100</td><td>zł</td></tr>
                <tr><td>47.</td><td>Piotr Marcin Jureccy – Andrychowska 2</td><td>100</td><td>zł</td></tr>
                <tr><td>48.</td><td>Kocjan</td><td>100</td><td>zł</td></tr>
                <tr><td>49.</td><td>Kołodziej – Lipowa 59</td><td>50</td><td>zł</td></tr>
                <tr><td>50.</td><td>Kozak – Kasztanowa 5</td><td>100</td><td>zł</td></tr>
                <tr><td>51.</td><td>Maria Kozak – Andrychowska 208</td><td>100</td><td>zł</td></tr>
                <tr><td>52.</td><td>T.S.Kozak – Andrychowska 234</td><td>100</td><td>zł</td></tr>
                <tr><td>53.</td><td>K.Kozieł – Andrychowska 165</td><td>200</td><td>zł</td></tr>
                <tr><td>54.</td><td>Zofia Kozieł – Modrzewiowa 20</td><td>100</td><td>zł</td></tr>
                <tr><td>55.</td><td>A.K.Krawczyk – Akacjowa</td><td>100</td><td>zł</td></tr>
                <tr><td>56.</td><td>K.M.Krawczyk – Akacjowa 82</td><td>100</td><td>zł</td></tr>
                <tr><td>57.</td><td>M.K.Krzaczyńscy – Kasztanowa 95</td><td>50</td><td>zł</td></tr>
                <tr><td>58.</td><td>Krzyścin – Pagórkowa 19</td><td>100</td><td>zł</td></tr>
                <tr><td>59.</td><td>Kubas – Andrychowska 380</td><td>50</td><td>zł</td></tr>
                <tr><td>60.</td><td>T.J.Kusak – Andrychowska 238</td><td>50</td><td>zł</td></tr>
                <tr><td>61.</td><td>Jerzy Kuska – Lipowa 73</td><td>100</td><td>zł</td></tr>
                <tr><td>62.</td><td>Leszek Kuska – Kasztanowa 2</td><td>100</td><td>zł</td></tr>
                <tr><td>63.</td><td>Stanisław Kuska – Kasztanowa 4</td><td>100</td><td>zł</td></tr>
                <tr><td>64.</td><td>M.A.Kuśpik – Andrychowska 174</td><td>100</td><td>zł</td></tr>
                <tr><td>65.</td><td>Łubiarz – Lipowa 65</td><td>100</td><td>zł</td></tr>
                <tr><td>66.</td><td>Makuch – Andrychowska 288</td><td>100</td><td>zł</td></tr>
                <tr><td>67.</td><td>D.Makuch – Andrychowska 232</td><td>50</td><td>zł</td></tr>
                <tr><td>68.</td><td>Józef Makuch – Akacjowa</td><td>100</td><td>zł</td></tr>
                <tr><td>69.</td><td>Sebastian Makuch – Andrychowska 184</td><td>200</td><td>zł</td></tr>
                <tr><td>70.</td><td>Waldemar Makuch – Akacjowa</td><td>100</td><td>zł</td></tr>
                <tr><td>71.</td><td>Antoni Mamoń – Andrychowska 131</td><td>100</td><td>zł</td></tr>
                <tr><td>72.</td><td>Ewelina Matyjasik</td><td>200</td><td>zł</td></tr>
                <tr><td>73.</td><td>Michalska</td><td>100</td><td>zł</td></tr>
                <tr><td>74.</td><td>Mirek – Lipowa 40</td><td>100</td><td>zł</td></tr>
                <tr><td>75.</td><td>Stanisław Młynarczyk – Akacjowa 56</td><td>200</td><td>zł</td></tr>
                <tr><td>76.</td><td>Wiktoria Noworyta</td><td>50</td><td>zł</td></tr>
                <tr><td>77.</td><td>E.Nykiel – Lipowa 72</td><td>100</td><td>zł</td></tr>
                <tr><td>78.</td><td>Pacyga</td><td>100</td><td>zł</td></tr>
                <tr><td>79.</td><td>Stanisław Patrzyk – Kasztanowa 133</td><td>200</td><td>zł</td></tr>
                <tr><td>80.</td><td>D.A.Pieczonka – Andrychowska 171</td><td>100</td><td>zł</td></tr>
                <tr><td>81.</td><td>M.M.Płachta – Bukowiecka 52</td><td>100</td><td>zł</td></tr>
                <tr><td>82.</td><td>A.W.Płonka – Kościelna 6</td><td>100</td><td>zł</td></tr>
                <tr><td>83.</td><td>Krystian Urszula Poręba</td><td>50</td><td>zł</td></tr>
                <tr><td>84.</td><td>S.W.Poręba</td><td>50</td><td>zł</td></tr>
                <tr><td>85.</td><td>M.R.</td><td>100</td><td>zł</td></tr>
                <tr><td>86.</td><td>Rozalia Rola – Andrychowska 225</td><td>100</td><td>zł</td></tr>
                <tr><td>87.</td><td>E.M.Skawina – Modrzewiowa 10</td><td>100</td><td>zł</td></tr>
                <tr><td>88.</td><td>Szatan – Andrychowska 272</td><td>100</td><td>zł</td></tr>
                <tr><td>89.</td><td>Szymańska Markiewicz – Andrychowska 343</td><td>200</td><td>zł</td></tr>
                <tr><td>90.</td><td>B.A.Szymańscy – Andrychowska 410</td><td>100</td><td>zł</td></tr>
                <tr><td>91.</td><td>A.A.Środa – Akacjowa 77</td><td>100</td><td>zł</td></tr>
                <tr><td>92.</td><td>Tysa – Modrzewiowa 12</td><td>100</td><td>zł</td></tr>
                <tr><td>93.</td><td>M.W.Wasztyl</td><td>100</td><td>zł</td></tr>
                <tr><td>94.</td><td>Władysława Ważydrąg – Źródlana</td><td>100</td><td>zł</td></tr>
                <tr><td>95.</td><td>D.Wiecheć – Andrychowska 263</td><td>50</td><td>zł</td></tr>
                <tr><td>96.</td><td>Łukasz Wiśniecki – Lipowa</td><td>100</td><td>zł</td></tr>
                <tr><td>97.</td><td>Wnętrzak</td><td>100</td><td>zł</td></tr>
                <tr><td>98.</td><td>Wojtala – Andrychowska 172</td><td>50</td><td>zł</td></tr>
                <tr><td>99.</td><td>Stanisława Kazimierz Ubik – Wrzosowa 2</td><td>100</td><td>zł</td></tr>
                <tr><td>100.</td><td>W.D.Ubik – Wrzosowa 4</td><td>100</td><td>zł</td></tr>
                <tr><td>101.</td><td>Antonina Kazimierz Zając – Kasztanowa 87</td><td>100</td><td>zł</td></tr>
                <tr><td>102.</td><td>Czesław Zając – Kasztanowa 123</td><td>100</td><td>zł</td></tr>
                <tr><td>103.</td><td>Janusz Zając – Andrychowska 320</td><td>100</td><td>zł</td></tr>
                <tr><td>104.</td><td>J.K.Zając – Andrychowska 101</td><td>100</td><td>zł</td></tr>
                <tr><td>105.</td><td>Jan Rozalia Zając – Andrychowska 376</td><td>100</td><td>zł</td></tr>
                <tr><td>106.</td><td>Krystyna Zając – Lipowa 75</td><td>100</td><td>zł</td></tr>
                <tr><td>107.</td><td>W.Zarzycki – Andrychowska 312</td><td>100</td><td>zł</td></tr>
                <tr><td>108.</td><td>Zdżalik – Wrzosowa 25</td><td>50</td><td>zł</td></tr>
                <tr><td>109.</td><td>Ziaja – Andrychowska</td><td>50</td><td>zł</td></tr>
                <tr><td>110.</td><td>A.Ł.Zięba – Andrychowska 351</td><td>200</td><td>zł</td></tr>
                <tr><td>111.</td><td>Andrychowska 121</td><td>100</td><td>zł</td></tr>
                <tr><td>112.</td><td>Andrychowska 164</td><td>100</td><td>zł</td></tr>
                <tr><td>113.</td><td>Andrychowska 167</td><td>200</td><td>zł</td></tr>
                <tr><td>114.</td><td>Andrychowska 245</td><td>100</td><td>zł</td></tr>
                <tr><td>115.</td><td>Andrychowska 260</td><td>50</td><td>zł</td></tr>
                <tr><td>116.</td><td>Andrychowska 270</td><td>50</td><td>zł</td></tr>
                <tr><td>117.</td><td>Andrychowska 301</td><td>100</td><td>zł</td></tr>
                <tr><td>118.</td><td>Andrychowska 338</td><td>50</td><td>zł</td></tr>
                <tr><td>119.</td><td>Bukowiecka 13</td><td>50</td><td>zł</td></tr>
                <tr><td>120.</td><td>Jasminowa 16</td><td>50</td><td>zł</td></tr>
                <tr><td>121.</td><td>Kasztanowa 10</td><td>200</td><td>zł</td></tr>
                <tr><td>122.</td><td>Kasztanowa 49</td><td>50</td><td>zł</td></tr>
                <tr><td>123.</td><td>Lipowa 22</td><td>50</td><td>zł</td></tr>
                <tr><td>124.</td><td>Wrzosowa 23</td><td>100</td><td>zł</td></tr>
                <tr><td>125.</td><td>Wrzosowa 27</td><td>50</td><td>zł</td></tr>
                </table>
            </div>
            <Footer />
        </>
)}

export default Ofiarodawcy