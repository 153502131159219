import Navigation from '../componets/Navigation'
import Footer from '../componets/Footer'

function IntencjeLowiczki() {
    return(
        <>
            <Navigation />
            <div className='article'>
                <div className='title'>Intecje Łowiczki</div>
                <ul>
                    <li>Niedziela 1.09 Dzięk. za tegoroczne plony (Dożynki)</li>
                    <li>Piatek 6.09 + Wiesław Dąbrowski (od syna Sebastiana z rodz.)</li>
                    <li>Niedziela 8.09 ++ Bogdan Kciuk, rodzice</li>
                    <li>Niedziela 15.09 + Kazimierz Stachura</li>
                    <li>Niedziela 22.09 + Józef Wolanin (od syna Janusza z rodz.)</li>
                    <li>Niedziela 29.09 Dzięk. z prośbą o błog. Boże dla Jakuba w 18 rocz. ur.</li>
                    <li>Środa 2.10 + Józefa Balcer (od wnuczki Moniki z rodz.)</li>
                    <li>Piątek 4.10 + Zofia Jezierska (od Stanisławy Matla i Zofii Odrobina)</li>
                    <li>Niedziela 6.10 + Andrzej Głąb 5 rocz.</li>
                    <li>Środa 9.10 + Stanisław Zięba (od szwagra Ludwika z żoną)</li>
                    <li>Niedziela 13.10 + Stanisław Sermak 6 rocz.</li>
                    <li>Środa 16.10 + Stanisław Ryszka (od chrześniaka Piotra z rodz.)</li>
                </ul>
            </div>
            <Footer />
        </>
    )
}

export default IntencjeLowiczki