import './App.css';
import Navigation from './componets/Navigation'
import Footer from './componets/Footer';
import p1 from './imgs/powodz.jpg'

function App() {
  return (
    <>
      <Navigation />
      <div className='article'>
        <div className='title'>"RAZEM DLA WAS"</div>
        <img className='centerimg' src={p1} alt="o1" />
        <p>W niedzielę 22 września po każdej Mszy Świętej ofiarą do puszek będzie można wesprzeć poszkodowanych w wyniku powodzi.</p>
      </div>
      <Footer />
    </>
  );
}

export default App;
