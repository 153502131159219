import '../App.css';
import Navigation from '../componets/Navigation'
import Footer from '../componets/Footer';
import { globalVar } from '../globalVar';
function Ogloszenia() {
  return (
    <>
      <Navigation />
      <div className='article'>
        <div className='title'>Ogłoszenia {globalVar.map((map,index) => {return map.niedziela})} </div>
        <p>Kalendarz Liturgiczny</p>
        <ul>
          <li>środa - święto św.Stanisława Kostki, zakonnika</li>
          <li>piątek - wspomnienie św.św.Andrzeja Kim Taegon, Pawła Chong Hasang i Towarzyszy, męczenników</li>
          <li>sobota - święto św.Mateusza, apostoła i ewangelisty</li>
        </ul>
        <p>
          W środę nowenna do MBNP, przed Mszą św. o godz. 18.00 różaniec w intencji
          młodzieży naszej Parafii przygotowującej się do sakramentu Bierzmowania, prowadzony
          przez III Różę.
        </p>
        <p>
          Młodzież naszej Parafii w tym dniu, na Mszy św. o godz. 18.00 rozpocznie formację
          przygotowującą do Bierzmowania (klasy VIII i VII). Proszę, by towarzyszyli im w tym
          dniu rodzice, chrześni i rodziny.
        </p>
        <p>
          Od jutra przyjmowane są intencje na przyszły rok.
        </p>
        <p>
          15 grudnia J.E.Ks.Bp Robert Chrząszcz, przeprowadzi wizytację kanoniczną w
          naszej Parafii – szczegóły podam po spotkaniu z Ks.Bp-m.
        </p>
        <p>
          Metropolita Krakowski Arcybiskup Marek Jędraszewski wydał dekret zezwalający
          na rozpoczęcie prac związanych z renowacją i modernizacją dachu naszej świątynii. Jeśli
          pogoda będzie sprzyjająca, prace rozpoczniemy w najbliższy wtorek.
        </p>
        <p>
          Dziękuję za sprzątanie świątynii na dzisiejszą niedzielę, i ofiarę 200zł, na przyszłą niedzielę troskę o świątynię powierzamy rodzinom:
        </p>
        <ul>
          <li>Marka i Grażyny Mamoń</li>
          <li>Waldemara i Bernadetty Kubas</li>
          <li>Mateusza i Kseni Klimek</li>
          <li>Dariusza i Anny Stokłosa</li>
          <li>Grażyny Makuch</li>
        </ul>
        <p>
          Dziękuję za sprzątanie kaplicy i ofiarę 200zł, w tym tygodniu troskę o kaplicę powierzamy:
        </p>
        <ul>
          <li>Aleksandrze Oleś</li>
          <li>Pawłowi Ziębie</li>
          <li>Alicji Kubackiej</li>
          <li>Marzenie Cichoń</li>
        </ul>
      </div>
      <Footer />
    </>
  );
}

export default Ogloszenia;
