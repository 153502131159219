import Navigation from '../componets/Navigation'
import Footer from '../componets/Footer'

function Zapowiedzi() {
    return(
        <>
            <Navigation />
            <div className='article'>
                <div className='title'>Zapowiedzi Przedślubne 9/2024</div>
                <table>
                    <tr><td>Sebastian Zięba</td></tr>
                    <tr><td>zam. Piotrowice ul.Kasztanowa 97</td></tr>
                    <tr><td>i</td></tr>
                    <tr><td>Agnieszka Masny</td></tr>
                    <tr><td>zam. Pietrzykowice ul.Stojałowskiego 17c</td></tr>
                </table>
            </div>
            <Footer />
        </>
    )
}

export default Zapowiedzi